$(() => {
  var toggleListPage = $('.current').parents('.js-sidebar-toggle-list');
  toggleListPage.show();
  toggleListPage.prev().addClass('admin-nav__nav-item-title--current');

  $('.js-sidebar-toggle').click(function (event) {
    var className = event.target.className.replace(/\s+/g, '');
    if (className === 'nav-link') {
      return;
    }

    $(this).find('.js-sidebar-toggle-list').slideToggle();
    $(this).find('.admin-nav__nav-item-title').toggleClass('admin-nav__nav-item-title--current');
  });

  // PageNation
  const z = $('.m_dummy-column').closest('.col-md-4');
  z.addClass('z-index_minus');
});
